import { getAuth, signOut } from "firebase/auth";
import { useEffect } from "react";
import { app } from "../firebase";
import { useNavigate } from "react-router-dom";

export default function LogoutPage(){
    const navigate = useNavigate();
    useEffect( () => {
        signOut(getAuth(app)).then(() => {
            navigate("/login");
        });
    }, [])
    return <></>
}