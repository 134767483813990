import Header from "../components/Header";


export function MainTemplate({ children }: { children: React.ReactNode }) {
    return (
        <div className="app">
            <Header />
            <div className="container" style={{paddingTop : 10}}>
                {children}  
            </div>
      </div>
    );
}