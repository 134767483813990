import React, { useEffect } from 'react';
import './App.css';
import { RouterProvider } from 'react-router-dom';
import router from './router';
import { useAppDispatch, useAppSelector } from './hooks';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { app } from './firebase';
import { saveUser } from './store/authSlice';
import { Spinner } from 'reactstrap';

function App() {
  const auth = getAuth(app);
  const [loading, setLoading] = React.useState(true);
  const user = useAppSelector((state) => state.auth.user);
  const dispatch = useAppDispatch();

  
  useEffect(() => {
    getAuth(app).authStateReady().then(() => {
      const user = getAuth(app).currentUser;
      if (user){
        console.log("User is logged in");
        saveUser({ email: user.email, uid: user.uid });
      }
      setLoading(false);
    });

    onAuthStateChanged(auth, (user) => {
      if (user) {
        dispatch(saveUser({ email: user.email, uid: user.uid }));
      } else {
        dispatch(saveUser(undefined));
      }
    });
  }, [auth, dispatch]);
  
  
  return ( <>{loading ? <Spinner /> : <RouterProvider router={router} />}</>
  );
}

export default App;
