import { useEffect } from "react";
import Header from "../components/Header";
import { useAppSelector } from "../hooks";
import { redirect, useNavigate } from "react-router-dom";


export function ProtectedRoute({ children }: { children: React.ReactNode }) {
    const user = useAppSelector((state) => state.auth.user);
    const navigate = useNavigate();

    useEffect(() => {
        if (!user) {
            console.log("Redirecting to login");    
            navigate("/login");
        }
    },[ user ]);
    return (
        <>
            {user && children}
        </>
    );
}