import React, { useEffect } from 'react';
import {
  Card,
  CardBody,
  CardTitle,
  Form,
  FormGroup,
  Label,
  Button,
  FormFeedback,
  Alert,
} from 'reactstrap';
import LoginTemplate from '../templates/LoginTemplate';
import { NavLink, redirect, useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector, useYupValidationResolver } from '../hooks';
import { AuthErrorCodes, getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { app } from '../firebase';
import FormInput from '../components/FormInput';
import { FirebaseError } from 'firebase/app';
import { saveUser } from '../store/authSlice';

type LoginValues = {
  email: string,
  password: string,
};

const LoginSchema = yup.object({
  email: yup.string().email().required(),
  password: yup.string().min(2).required(),
})



const Login = () => {
  const resolver = useYupValidationResolver(LoginSchema)
  const { register: _register, handleSubmit, formState: { errors } } = useForm<LoginValues>({resolver})
  const [error, setError] = React.useState<string | null>(null)
  
  const onSubmit: SubmitHandler<LoginValues> = async (data: LoginValues) => {  
    try{
      const user = await signInWithEmailAndPassword(getAuth(app), data.email, data.password)
    } catch (error : any) {
      if (error instanceof FirebaseError) {
        switch (error.code) {
          case AuthErrorCodes.INVALID_LOGIN_CREDENTIALS:
            setError("Invalid login credentials")
            break;
          default:
            setError("An error occurred")
        }
      }
      
    };
  }

  
  return (
      <LoginTemplate>
          <Card className="border-0" style={{ width: '80%', maxWidth: '400px' }}>
            <CardBody>
              <CardTitle className="text-center mb-4">
                <h3 className="fw-bold">Welcome Back</h3>
                <p className="text-muted">Sign in to your account</p>
              </CardTitle>
              <Form onSubmit={handleSubmit(onSubmit)}>
                { error && <Alert color='danger'>
                  {error}
                </Alert> }
                <FormGroup>
                  <Label for="email" className="fw-bold">Email</Label>
                  <FormInput
                    id="email"
                    name="email"
                    type="email"
                    placeholder="Enter your email"
                    register={_register}
                    invalid={errors.email}
                  />
                  <FormFeedback>
                    { errors.email?.message }
                  </FormFeedback>
                </FormGroup>
                <FormGroup>
                  <Label for="password" className="fw-bold">Password</Label>
                  <FormInput
                    id="password"
                    name="password"
                    type="password"
                    placeholder="Enter your password"
                    register={_register}
                    invalid={errors.password}
                  />
                  <FormFeedback>
                    { errors.password?.message }
                  </FormFeedback>                  
                </FormGroup>
                <Button type="submit" color="primary" block className="mt-4">
                  Sign In
                </Button>
              </Form>
              <hr className="my-4" />
              <p className="text-center small">
                Don't have an account?{' '}
              
                <NavLink to={'/register'} className="text-decoration-none">
                  Sign In
                </NavLink>
              </p>
            </CardBody>
          </Card>
        </LoginTemplate>  
  );
};

export default Login;