import {
    createBrowserRouter,
  } from "react-router-dom";
import HomePage from "./pages/HomePage";
import LoginPage from "./pages/LoginPage";
import RegisterPage from "./pages/RegisterPage";
import { ProtectedRoute } from "./utils/ProtectedRoutes";
import LogoutPage from "./pages/LogoutPage";
import NotFoundPage from "./pages/NotFoundPage";
  
  export default createBrowserRouter([
    {
      path: "/",
      element: <ProtectedRoute><HomePage/></ProtectedRoute>,
    },
    {
      path: "/login",
      element: <LoginPage />,
    },
    {
      path: "/register",
      element: <RegisterPage />,
    },
    { path: "/logout", element: <LogoutPage /> },
    { path: "*", element: <NotFoundPage /> },
  ]);