import { MainTemplate } from "../templates/MainTemplate";

function Home() {
    return (
        <MainTemplate>
            <div>
                <h1>Home</h1>
            </div>
        </MainTemplate>
    );
}

export default Home;
