import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Container,
  Row,
  Col,
} from 'reactstrap';
import { useAppSelector } from '../hooks';

const LoginTemplate = ({ children }: { children: React.ReactNode }) => {
    const navigate = useNavigate();
    const user = useAppSelector((state) => state.auth.user);
    
    useEffect( () => {
        if (user){
        let redirect = "/";
        if (window.location.search) {
            const redirectSearch = new URLSearchParams(window.location.search).get("redirect");
            if (redirectSearch) {
            redirect = redirectSearch;
            }
        }
        navigate(redirect);
        }
    }, [user]);
    
    return (
    <Container fluid className="vh-100 p-0">
      <Row className="h-100 g-0">
        {/* Left Section - Form */}
        <Col md="6" className="d-flex align-items-center justify-content-center">
            {children}
        </Col>

        {/* Right Section - Image */}
        <Col md="6" className="d-none d-md-block">
          <div
            className="h-100"
            style={{
                backgroundImage: `url(${require('../assets/images/logo.png')})`,
                backgroundSize: 'contain',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundColor: 'rgb(18, 15, 62)',
            }}
          ></div>
        </Col>
      </Row>
    </Container>
  );
};

export default LoginTemplate;