import React, { useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import {
  Card,
  CardBody,
  CardTitle,
  Form,
  FormGroup,
  Label,
  Button,
  FormFeedback,
  Alert,
} from 'reactstrap';
import LoginTemplate from '../templates/LoginTemplate';
import { useAppSelector, useYupValidationResolver } from '../hooks';
import { useForm } from 'react-hook-form';
import FormInput from '../components/FormInput';
import { createUserWithEmailAndPassword, getAuth } from 'firebase/auth';
import { app } from '../firebase';
import { FirebaseError } from 'firebase/app';

export type CreateUserValues = {
  email: string,
  password: string,
  confirmPassword: string,
}

const CreateUserValidationSchema = yup.object({
  email: yup.string().email().required(),
  password: yup.string().required(),
  confirmPassword: yup.string().required().oneOf([yup.ref('password')], 'Passwords must match'),
})


const RegisterPage = () => {
  const resolver = useYupValidationResolver(CreateUserValidationSchema)
  const { register: _register, handleSubmit, formState: { errors } } = useForm<CreateUserValues>({resolver})
  const [error, setError] = React.useState<string | null>(null)
  const user = useAppSelector((state) => state.auth.user); 
  const navigate = useNavigate();

  useEffect( () => {
    if (user) {
      let redirect = "/";
      if (window.location.search) {
        const redirectSearch = new URLSearchParams(window.location.search).get("redirect");
        if (redirectSearch) {
          redirect = redirectSearch;
        }
      }
      navigate(redirect);

    }
  }, [user]);

  const onSubmit = async (data: CreateUserValues) => {
    try {
      await createUserWithEmailAndPassword(getAuth(app), data.email, data.password);
    }catch (error : any) {
        if (error instanceof FirebaseError) {
          switch (error.code) {
            case 'auth/email-already-in-use':
              setError("Email already in use")
              break;
            default:
              setError("An error occurred")
          }
        }
    }

  }


  return (
    <LoginTemplate>
            <Card className="border-0" style={{ width: '80%', maxWidth: '400px' }}>
            <CardBody>
              <CardTitle className="text-center mb-4">
                <h3 className="fw-bold">Create Your Account</h3>
              </CardTitle>
              <Form onSubmit={handleSubmit(onSubmit)}>
                { error && <Alert color="danger">{error}</Alert> }
                <FormGroup>
                  <Label for="email" className="fw-bold">Email</Label>
                  <FormInput
                    id="email"
                    name="email"
                    type="email"
                    placeholder="Enter your email"
                    register={_register}
                    invalid={errors.email}
                  />
                  <FormFeedback>{errors.email?.message}</FormFeedback>
                </FormGroup>
                <FormGroup>
                  <Label for="password" className="fw-bold">Password</Label>
                  <FormInput
                    id="password"
                    name="password"
                    type="password"
                    placeholder="Create a password"
                    invalid={errors.password}
                    register={_register}
                  />
                  <FormFeedback>{errors.password?.message}</FormFeedback>
                </FormGroup>
                <FormGroup>
                  <Label for="confirmPassword" className="fw-bold">Confirm Password</Label>
                  <FormInput
                    id="confirmPassword"
                    name="confirmPassword"
                    type="password"
                    placeholder="Confirm your password"
                    register={_register}
                    invalid={errors.confirmPassword}
                  />
                  <FormFeedback>{errors.confirmPassword?.message}</FormFeedback>
                </FormGroup>
                <Button color="primary" block className="mt-4">
                  Sign Up
                </Button>
              </Form>
              <hr className="my-4" />
              <p className="text-center small">
                Already have an account?{' '}
                <NavLink to={'/login'} className="text-decoration-none">
                  Sign In
                </NavLink>
              </p>
            </CardBody>
          </Card>
    </LoginTemplate>
  );
};

export default RegisterPage;